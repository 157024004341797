define(['app', '$window'], (app, $window) => {

  const horizontalFacets = () => {
    const component = {};
    const PRICE_FACET_DELIMITER = ':';
    const COMPONENT_CLASS_NAME = 'horizontalFacets';
    const FACET_NAME_MAX_LINES_OF_TEXT = 3;

    const _config = {
      selectors: {
        brandFacet: '.brandGroup_facetValue',
        facet: '[data-facet-filter]',
        facetContent: '.facetDropdown_content',
        dropdownButton: '.facetDropdown_button',
        chevronDown: '.facetDropdown_chevronDown',
        chevronUp: '.facetDropdown_chevronUp',
        displayHideFiltersButton: '.horizontalFacets_filterButton',
        moreFilters: '.horizontalFacets_moreFilters',
        lessFilters: '.horizontalFacets_lessFilters',
        facetCategories: '.horizontalFacets_facetCategories',
        priceFacet: '[data-component="priceFacet"]',
        listPage: '.responsiveProductListPage',
        horizontalFacetsWrapper: '.responsiveProductListPage_horizontalFacets',
        productListProductsWrapper: '.productListProducts',
        facetContentBrands: '.facetDropdown_content.brands',
        westendHeaderSticky: '.westendHeader-sticky',
        categoryName: '.facetDropdown_name'
      },
      attrs: {
        key: 'data-facet-key',
        value: 'data-facet-value',
        selected: 'data-selected',
        state: 'data-state',
        open: 'data-open',
        fieldName: 'data-field-name',
        selectedValue: 'data-selected-value',
        expanded: 'aria-expanded',
        openFilters: 'data-open-filters',
        sticky: 'data-sticky'
      },
      channels: {
        update: 'responsiveProductListPage/update',
        close: 'horizontalFacets/close',
        priceRange: 'priceFacet/range'
      },
      channelsToRemove: {
        change: 'horizontalFacets/update',
        click: 'horizontalFacets/click',
        resize: 'horizontalFacets/resize',
        dropdownClose: 'facetDropdown/close',
        facetClick: 'facetDropdown/click',
        open: 'horizontalFacets/open',
        restorePrevSelections: 'horizontalFacets/prevSelections',
        horizontalFacetsError: 'responsiveProducts/horizontalFacets'
      }
    };

    const GA_EVENT_CATEGORY = 'Facet Engagement';
    const GA_EVENT_ACTION_OPEN_FILTERS = 'Opens extra filters';
    const GA_EVENT_ACTION_CLOSE_FILTERS = 'Closes extra filters';

    const MAX_ALLOWED_DROPDOWNS_DESKTOP = 6;
    const MAX_ALLOWED_DROPDOWNS_TABLET = 4;
    const FACETS_WRAPPER_HEIGHT = document.querySelector(_config.selectors.horizontalFacetsWrapper).clientHeight;

    const _init = (element) => {

      component.element = element;
      component.facetFilters = component.element.querySelectorAll(component.config.selectors.facet);
      component.priceFacet = component.element.querySelector(component.config.selectors.priceFacet);
      component.facetContents = Array.from(component.element.querySelectorAll(component.config.selectors.facetContent));
      component.facetContentsBrands = component.element.querySelector(component.config.selectors.facetContentBrands);
      component.displayHideFiltersButton = component.element.querySelector(component.config.selectors.displayHideFiltersButton);
      component.moreFilters = component.element.querySelector(component.config.selectors.moreFilters);
      component.lessFilters = component.element.querySelector(component.config.selectors.lessFilters);
      component.facetCategories = component.element.querySelector(component.config.selectors.facetCategories);
      component.listPageWrapper = document.querySelector(component.config.selectors.listPage);
      component.horizontalFacetsWrapper = document.querySelector(component.config.selectors.horizontalFacetsWrapper);
      component.productListProductsWrapper = document.querySelector(component.config.selectors.productListProductsWrapper);
      component.initialPostion = component.element.offsetTop;
      component.categoryNames = Array.from(component.element.querySelectorAll(component.config.selectors.categoryName));
      component.changes = false;
      component.scrollPos = 0;

      if (component.listPageWrapper.hasAttribute(component.config.attrs.openFilters)) {
        component.displayHideFilters();
      }

      component.initialiseMaxHeightForCategory();
      component.subscribe();
      component.toggleFiltersButton();
      component.addEventListeners();
      component.build();

      return component;
    };

    const _subscribe = () => {
      component.clearSubscribedChannels();
      app.subscribe(component.config.channelsToRemove.change, component.updateFacetFilters);
      app.subscribe(component.config.channelsToRemove.click, component.detectOutsideClick);
      app.subscribe(component.config.channelsToRemove.dropdownClose, component.sendFilters);
      app.subscribe(component.config.channelsToRemove.facetClick, component.facetClick);
      app.subscribe(component.config.channelsToRemove.open, component.displayHideFilters);
      app.subscribe(component.config.channelsToRemove.horizontalFacetsError, component.emptyResults);
      app.subscribe(component.config.channelsToRemove.restorePrevSelections, component.restorePrevSelections);
      app.subscribe(component.config.channelsToRemove.resize, component.toggleFiltersButton);
    };

    const _addEventListeners = () => {
      $window.addEventListener('scroll', component.doScroll);
      component.displayHideFiltersButton.addEventListener('click', component.displayHideFilters);
    };

    const _doScroll = () => {
      let currentScrollPos = (document.body.getBoundingClientRect()).top;
      if(currentScrollPos > component.scrollPos) {
        component.stickyHeader(true, $window.pageYOffset);
      } else {
        component.stickyHeader(false, $window.pageYOffset);
      }
      component.scrollPos = currentScrollPos;
    };

    const _stickyHeader = (isSticky, scrollPos) => {
      if (isSticky && scrollPos >= component.initialPostion) {
        if(($window.innerHeight + $window.pageYOffset) <= (document.body.offsetHeight - document.querySelector('footer').offsetHeight)){
          component.element.classList.add('sticky');
          component.horizontalFacetsWrapper.classList.add('sticky');
          let westendHeaderHeight = document.querySelector(component.config.selectors.westendHeaderSticky);
          if (westendHeaderHeight) {
            component.horizontalFacetsWrapper.style.top = westendHeaderHeight.clientHeight + 'px' ;
            component.productListProductsWrapper.style.paddingTop = FACETS_WRAPPER_HEIGHT + 'px';
            if (component.facetContentsBrands) {
              component.facetContentsBrands.setAttribute(component.config.attrs.sticky, '')
            }
          }
        }
      } else {
        component.element.classList.remove('sticky');
        component.horizontalFacetsWrapper.classList.remove('sticky');
        component.productListProductsWrapper.style.paddingTop =  '0px' ;
        if (component.facetContentsBrands && component.facetContentsBrands.hasAttribute(component.config.attrs.sticky)) {
          component.facetContentsBrands.removeAttribute(component.config.attrs.sticky)
        }
      }
    };

    const _build = () => {
      component.facets = Array.from(component.facetFilters).map(facetFilter => {
        if (!facetFilter.hasAttribute('data-selected')) {
          return null;
        }

        const key = facetFilter.getAttribute(component.config.attrs.key);
        const value = facetFilter.getAttribute(component.config.attrs.value);

        return `${key}:${value}`;
      }).filter(f => f !== null);

      if (component.priceFacet) {
        component.appendPriceFacetFilters();
      }
    };

    const _appendPriceFacetFilters = () => {
      if (component.priceFacet.getAttribute(component.config.attrs.selected) === 'true') {
        let fieldName = component.priceFacet.getAttribute(component.config.attrs.fieldName);
        let selectedValue = component.priceFacet.getAttribute(component.config.attrs.selectedValue);
        component.facets = component.facets.concat([`${fieldName}:${selectedValue}`]);
      }
    };

    const _facetClick = (facet, pushToArray) => {
      if (facet) {
        let key = facet.getAttribute(component.config.attrs.key);
        let value = facet.getAttribute(component.config.attrs.value);
        let facetData = `${key}:${value}`;
        if (pushToArray) {
          component.facets.push(facetData);
        } else {
          component.facets = component.facets.filter(f => {
            let keyAndValue = f.split(':');
            return keyAndValue[0] !== key || keyAndValue[1] !== value
          });
        }
      }
    };

    const _updateFacetFilters = (newFacetFilters, clearSelections, forceUpdate, shouldReset) => {
      if (clearSelections !== undefined) {
        if (clearSelections === false) {
          if (forceUpdate) {
            component.facets = component.facets.filter((facet) =>
              newFacetFilters[0].split(':')[0] !== facet.split(':')[0]);
          }
          if (!shouldReset) {
            component.facets = component.facets.concat(newFacetFilters);
          }
        } else {
          component.facets = component.facets.filter((facet) => !newFacetFilters.includes(facet));
        }

        if (forceUpdate) {
          component.sendFilters(true);
        }
      }
    };

    const _detectOutsideClick = (event, escKeyClicked) => {
      if (escKeyClicked) {
        component.escKeyClicked(event);
      } else if (component.outsideClick(event) || !component.element.contains(event.target)) {
        app.publish(component.config.channels.close, event);
      }
    };

    const _shouldUpdate = () => {
      return Array.from(component.facetFilters).filter(facet => {
        return facet.hasAttribute('data-clicked') ^ facet.hasAttribute('data-selected');
      })[0];
    };

    const _clearSubscribedChannels = () => {
      for (let channel in component.config.channelsToRemove) {
        if (component.config.channelsToRemove.hasOwnProperty(channel)) {
          app.clear(component.config.channelsToRemove[channel]);
        }
      }
    };

    const _displayHideFilters = () => {
      let state = component.displayHideFiltersButton.getAttribute(component.config.attrs.state);
      if (state === 'closed') {
        app.publish('tracking/record', GA_EVENT_CATEGORY, GA_EVENT_ACTION_OPEN_FILTERS);
        component.listPageWrapper.setAttribute('data-open-filters', 'true');
        component.displayHideFiltersButton.setAttribute(component.config.attrs.state, 'open');
        component.displayHideFiltersButton.setAttribute(component.config.attrs.expanded, 'true');
      } else {
        app.publish('tracking/record', GA_EVENT_CATEGORY, GA_EVENT_ACTION_CLOSE_FILTERS);
        component.listPageWrapper.removeAttribute('data-open-filters');
        component.displayHideFiltersButton.setAttribute(component.config.attrs.state, 'closed');
        component.displayHideFiltersButton.setAttribute(component.config.attrs.expanded, 'false');
      }

      component.moreFilters.classList.toggle('hide');
      component.lessFilters.classList.toggle('hide');
      component.facetCategories.classList.toggle('allFilters');

    };

    const _toggleFiltersButton = () => {
      if (Array.from(component.facetContents).length > component.getMaxAllowedFacetsPerRow()) {
        component.displayHideFiltersButton.classList.remove('hide');
      } else if (!component.displayHideFiltersButton.classList.contains('hide')) {
        component.displayHideFiltersButton.classList.add('hide');
      }
    };

    const _sendFilters = (update) => {
      if (update || component.shouldUpdate()) {
        app.publish(component.config.channels.update, {facetFilters: component.facets.join('|')});
      }
    };

    const _escKeyClicked = (e) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        app.publish(component.config.channels.close);
      }
    };

    const _getMaxAllowedFacetsPerRow = () => {
      return $window.screen.width >= 1200 ? MAX_ALLOWED_DROPDOWNS_DESKTOP : MAX_ALLOWED_DROPDOWNS_TABLET;
    };

    const _emptyResults = () => {
      app.publish(component.config.channels.priceRange);
    };

    const _restorePrevSelections = (prevSelection) => {
      component.facets = component.facets
        .filter(f => f.split(PRICE_FACET_DELIMITER)[0] !== prevSelection[0].split(PRICE_FACET_DELIMITER)[0])
        .concat(prevSelection);
    };

    const _outsideClick = (event) => {
      let targetClass = event.target.classList[0];
      if (targetClass) {
        return targetClass.includes(COMPONENT_CLASS_NAME)
          && !component.displayHideFiltersButton.contains(event.target);
      }
      return false;
    };

    const _initialiseMaxHeightForCategory = () => {
      let lineHeight = parseInt(window.getComputedStyle(component.categoryNames[0]).lineHeight);
      component.categoryNames
        .forEach(name => (name.style.maxHeight = (lineHeight * FACET_NAME_MAX_LINES_OF_TEXT) + 'px'));

    };

    component.config = _config;
    component.init = _init;
    component.facetClick = _facetClick;
    component.addEventListeners = _addEventListeners;
    component.subscribe = _subscribe;
    component.updateFacetFilters = _updateFacetFilters;
    component.build = _build;
    component.detectOutsideClick = _detectOutsideClick;
    component.shouldUpdate = _shouldUpdate;
    component.clearSubscribedChannels = _clearSubscribedChannels;
    component.displayHideFilters = _displayHideFilters;
    component.toggleFiltersButton = _toggleFiltersButton;
    component.sendFilters = _sendFilters;
    component.appendPriceFacetFilters = _appendPriceFacetFilters;
    component.escKeyClicked = _escKeyClicked;
    component.getMaxAllowedFacetsPerRow = _getMaxAllowedFacetsPerRow;
    component.doScroll = _doScroll;
    component.stickyHeader = _stickyHeader;
    component.emptyResults = _emptyResults;
    component.restorePrevSelections = _restorePrevSelections;
    component.outsideClick = _outsideClick;
    component.initialiseMaxHeightForCategory = _initialiseMaxHeightForCategory;

    return component;
  };

  return horizontalFacets;
});
